import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CallToAction = ({ item, color, internal }) => {
  const content = (
    <>
      <div
        className={`transition duration-500 ease-in-out w-98 h-98 hidden md:flex flex-col items-center justify-around rounded-md shadow-lg p-8 my-4 mx-4 transform hover:scale-110 cursor-pointer select-none ${
          color ?? 'bg-s4tk-blue'
        }`}
      >
        <p className="font-baloo font-bold text-white text-3xl text-center">
          {item.title}
        </p>
        <FontAwesomeIcon
          className="text-white text-9xl"
          icon={[item.icon.prefix, item.icon.title]}
        />
        {item.description && (
          <p className="font-baloo font-bold text-white text-lg text-center">
            {item.description}
          </p>
        )}
      </div>

      <div
        className={`w-full select-none h-48 flex md:hidden flex-row items-center justify-start rounded-md shadow-lg p-4 my-4 ${
          color ?? 'bg-s4tk-blue'
        }`}
      >
        <div className="w-1/4 flex-grow h-full flex items-center justify-center">
          <FontAwesomeIcon
            className="text-white text-5xl xs:text-7xl"
            icon={[item.icon.prefix, item.icon.title]}
          />
        </div>
        <div className="w-2/3 flex-grow-0 h-full flex flex-col items-start justify-center pl-2">
          <p className="font-baloo font-bold text-white text-lg xs:text-2xl">
            {item.title}
          </p>
          <p className="font-baloo text-white text-md xs:text-xl">
            {item.description}
          </p>
        </div>
      </div>
    </>
  );

  return internal ? (
    <>
      <Link className="hidden md:flex" to={item.url}>
        {content}
      </Link>
      <Link className="w-full flex md:hidden" to={item.url}>
        {content}
      </Link>
    </>
  ) : (
    <>
      <a className="hidden md:flex" href={item.url}>
        {content}
      </a>
      <a className="w-full flex md:hidden" href={item.url}>
        {content}
      </a>
    </>
  );
};

export default CallToAction;
