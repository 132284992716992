import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import { Carousel } from 'react-responsive-carousel';
import '../../styles/carousel-fix.css';

export const HomeHero = ({ image, images, title }) => {
  return (
    <div className="w-full h-screen relative flex justify-start items-end rounded-b-3xl">
      <Carousel
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        autoPlay
        transitionTime={1000}
        interval={7000}
        showThumbs={false}
        className="w-full h-full absolute rounded-b-3xl"
      >
        {images.map((item, index) => (
          <div className="h-screen rounded-b-3xl">
            <PreviewCompatibleImage
              key={index}
              className="h-full rounded-b-3xl"
              imgStyle={{ objectFit: 'cover' }}
              image={item}
              alt="home-image"
            />
          </div>
        ))}
      </Carousel>
      <div className="w-full h-full z-10 bg-black opacity-25 absolute top-0 left-0 rounded-b-3xl" />
      <div className="w-full lg:w-1/2 h-full flex flex-col justify-around items-center lg:items-start z-20 px-4 lg:px-32">
        <p className="text-4xl lg:text-6xl text-center lg:text-left font-baloo font-semibold text-white tracking-tight">
          {title}
        </p>
      </div>
    </div>
  );
};

export default HomeHero;
