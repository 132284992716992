import React from 'react';

import ImpactItem from './ImpactItem';

export const Impact = ({ impactItems }) => {
  return (
    <div className="w-full px-4 md:px-16 py-16 flex flex-col flex-wrap bg-s4tk-green justify-around items-center">
      <p className="font-baloo font-bold text-white text-4xl sm:text-5xl lg:text-7xl lg:self-start mb-8 text-left sm:text-justify mt-4">
        {impactItems.caption}
      </p>
      <div className="w-full flex flex-row flex-wrap justify-around items-center">
        {impactItems.items.map((item, index) => (
          <ImpactItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default Impact;
