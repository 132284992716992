import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
// import Features from "../components/Features";
// import BlogRoll from "../components/BlogRoll";
import HomeHero from '../components/Home/HomeHero';
import CTA from '../components/Home/CTA';
import Video from '../components/Home/Video';
import Impact from '../components/Home/Impact';

export const IndexPageTemplate = ({
  title,
  images,
  ctaItems,
  videoInfo,
  impactItems
}) => (
  <div className="w-full flex flex-col bg-s4tk-blue">
    <HomeHero images={images} title={title} />
    <CTA ctaItems={ctaItems} />
    <Video videoInfo={videoInfo} />
    <Impact impactItems={impactItems} />
  </div>
);

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        images={frontmatter.images}
        ctaItems={frontmatter.ctaItems}
        videoInfo={frontmatter.videoInfo}
        impactItems={frontmatter.impactItems}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        images {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ctaItems {
          caption
          items {
            title
            description
            icon {
              prefix
              title
            }
            url
          }
        }
        videoInfo {
          caption
          url
        }
        impactItems {
          caption
          items {
            value
            description
            icon {
              prefix
              title
            }
          }
        }
      }
    }
  }
`;
