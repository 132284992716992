import React from 'react';

import CallToAction from '../CallToAction';

export const CTA = ({ ctaItems }) => {
  return (
    <div className="w-full px-4 md:px-16 py-16 flex flex-col flex-wrap bg-s4tk-blue justify-around items-center">
      <p className="font-baloo font-bold text-white text-4xl sm:text-5xl lg:text-7xl lg:self-start mb-8 text-center">
        {ctaItems.caption}
      </p>
      <div className="w-full flex flex-row flex-wrap justify-around items-center">
        {ctaItems.items.map((item, index) => (
          <CallToAction
            color="bg-s4tk-darkBlue"
            internal
            key={index}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};

export default CTA;
