import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImpactItem = ({ item }) => {
  return (
    <div className="w-102 h-102 flex flex-col items-center justify-around p-8 my-4 mx-4">
      <FontAwesomeIcon
        className="text-white text-10xl"
        icon={[item.icon.prefix, item.icon.title]}
      />
      <p className="font-baloo font-bold text-white text-6xl text-center">
        {item.value}
      </p>
      <p className="font-baloo font-bold text-white text-4xl text-center">
        {item.description}
      </p>
    </div>
  );
};

export default ImpactItem;
